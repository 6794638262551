.info-window {
	position: absolute;
	bottom: 60px;
	left: -22px;
	width: 180px;
	height: auto;
	background-color: white;
	box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
	padding: 10px;
	font-size: 14px;
	z-index: 1000000;
}

.info-window:before {
	content: '';
	position: absolute;
	top: -webkit-calc(100% - 10px);
	top: calc(100% - 10px);
	left: 30px;
	height: 20px;
	width: 20px;
	background-color: white;
	transform: rotate(45deg);
}

.info-window > .plain-text {
	padding-top: 10px;
}

.info-window > .co-ordinates {
	padding-top: 10px;
	padding-left: 10px;
}
