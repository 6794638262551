.filter-list {
	@media (min-width: 480px) {
		.list-column {
			width: 100% !important;
		}
	}

	@media (min-width: 768px) {
		max-height: 500px !important;
		.list-column {
			width: 50% !important;
		}
	}

	@media (min-width: 992px) {
		max-height: 350px !important;
		.list-column {
			width: 33% !important;
		}
	}
}
