// Global settings for the page header

.page-header {
	.page-header-content {
		padding-top: 2rem;
		padding-bottom: 2rem;
		// Base styling for the page header title
		.page-header-title {
			line-height: normal;
			font-size: 2.25rem;
			font-weight: 500;
			display: flex;
			align-items: center;
			margin-bottom: 0.5rem;
			.page-header-icon {
				margin-right: 0.5rem;
				display: inline-flex;
			}
			.page-header-title {
				text-transform: capitalize;
			}
		}
		// Base styling for the page header subtitle
		.page-header-subtitle {
			text-transform: capitalize;
			line-height: normal;
			font-size: 1.15rem;
			margin: 0;
		}
	}
}
