// Vertically centers content within buttons if content is different heights
.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

// Applies the Accent colors to button classes
.btn-accent-1 {
	background-color: var(--accent-1);
	color: $white;
}

.btn-accent-2 {
	background-color: var(--accent-2);
	color: $white;
}

.btn-accent-3 {
	background-color: var(--accent-3);
	color: $white;
}

.btn-accent-4 {
	background-color: var(--accent-4);
	color: $white;
}
