// Override Bootstrap typography variables

$font-family-sans-serif: 'Metropolis', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;

$headings-font-weight: 500 !default;

$font-family-base: $font-family-sans-serif !default;

$font-weight-normal: 400 !default;
$font-weight-bold: 500 !default;

$font-size-root: null !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-xs: $font-size-base * 0.775 !default;

$line-height-xs: 1 !default;

$h1-font-size: $font-size-base * 1.5 !default;
$h2-font-size: $font-size-base * 1.4 !default;
$h3-font-size: $font-size-base * 1.3 !default;
$h4-font-size: $font-size-base * 1.2 !default;
$h5-font-size: $font-size-base * 1.1 !default;
$h6-font-size: $font-size-base !default;

$small-font-size: 0.875em !default;

$lead-font-size: $font-size-base * 1.1 !default;
$lead-font-weight: 400 !default;

$text-muted: $gray-500 !default;

$display1-size: 5.5rem !default;
$display2-size: 4.5rem !default;
$display3-size: 3.5rem !default;
$display4-size: 2.5rem !default;

$enable-responsive-font-sizes: true;
