.connectors-container {
	display: flex;
	flex-wrap: wrap;
	gap: 7px;
}

.connector-item {
	cursor: context-menu;
}

.connector-badge {
	font-size: 75%;
	font-weight: 400;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.35rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.connector-extra-info {
	display: flex;
	align-items: center;
	min-width: 145px;
	border: 1px solid $gray-100;

	.connector-inner {
		display: flex;
		flex-direction: column;
		text-align: left;
		gap: 3px;
		padding: 0.3rem;
	}
}

.connector-extra-info-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 35px;
	font-size: 80%;
	padding: 0.6em;
	border-radius: 0.35rem 0 0 0.35rem;
}
