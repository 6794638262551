.no-coords-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-coords-icon {
    font-size: 7em;
    color: var(--primary);
}