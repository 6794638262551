// This file is intentionally blank
// Use this file to override SCSS within the project, or to add new SCSS for creating your own CSS components
// Variable Colors from theme
:root {
	--primary: #bf21b5;
	--primary-75: #bf21b5b8;
	--primary-50: #bf21b589;
	--primary-25: #bf21b541;

	--secondary: #1fbfaf;
	--secondary-75: #1fbfafb8;
	--secondary-50: #1fbfaf89;
	--secondary-25: #1fbfaf41;

	--accent-1: #bf50b8;
	--accent-2: #f2f2f2;
	--accent-3: #595959;
	--accent-4: #333333;
}

$primary: var(--primary);
$primary-75: var(--primary-75);
$primary-50: var(--primary-50);
$primary-25: var(--primary-25);

$secondary: var(--secondary);
$secondary-75: var(--secondary-75);
$secondary-50: var(--secondary-50);
$secondary-25: var(--secondary-25);

$accent-1: var(--accent-1);
$accent-2: var(--accent-2);
$accent-3: var(--accent-3);
$accent-4: var(--accent-4);

$btn-focus: var(--primary);
$box-shadow: var(--primary);

.card-header,
.text-primary,
.btn-outline-primary,
a {
	color: var(--primary) !important;
}

.btn-primary {
	background-color: var(--primary) !important;
	border-color: var(--primary) !important;
}

.active {
	background-color: var(--primary) !important;
	border-color: var(--primary) !important;
	color: #ffffff !important;
}

.btn.btn-outline-primary:focus {
	background-color: var(--primary) !important;
	color: #ffffff !important;
}

.btn.btn-outline-primary:hover {
	background-color: var(--primary) !important;
	color: #ffffff !important;
}

.btn-outline-primary {
	border-color: var(--primary) !important;
}

.text-secondary,
.btn-outline-secondary {
	color: var(--secondary) !important;
}

.btn-secondary {
	background-color: var(--secondary) !important;
}

.btn-outline-secondary {
	border-color: var(--secondary) !important;
}

.btn-outline-secondary.active {
	background-color: var(--secondary) !important;
	border-color: var(--secondary) !important;
	color: #ffffff !important;
}

.btn.btn-outline-secondary:focus {
	background-color: var(--secondary) !important;
	color: #ffffff !important;
}

.btn.btn-outline-secondary:hover {
	background-color: var(--secondary) !important;
	color: #ffffff !important;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
	color: #212529;
	background-color: var(--primary);
	border-color: rgb(119, 127, 204);
}
