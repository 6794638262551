.map-marker {
	width: 30px;
	position: absolute;
	transform: translate(-50%, -100%);
}

.map-marker > img {
	width: 80%;
	cursor: pointer;
}
