// Base styling for the topnav

.topnav {
	padding-left: 0;
	height: $topnav-base-height;
	z-index: $zindex-topnav;
	font-size: 0.9rem;
	background-color: $white;
	.navbar-brand {
		width: $sidenav-base-width;
		padding-left: $navbar-padding-x;
		padding-right: $navbar-padding-x;
		margin: 0;
		font-size: 1rem;
		font-weight: bold;
		text-align: center;
		// img {
		// 	height: 1.8rem;
		// }
	}
	&.navbar-dark {
		#sidebarToggle {
			color: $topnav-dark-toggler-color;
		}
		.navbar-brand {
			color: $white;
		}
	}
	&.navbar-light {
		#sidebarToggle {
			color: $topnav-light-toggler-color;
		}
		.navbar-brand {
			color: $gray-800;
		}
	}
}
