.balance-widget {
	display: flex;
	cursor: default;
	.card-title {
		margin-bottom: 0;
	}
	.card-value-heading {
		text-align: right;
	}
	.card-value {
		text-align: right;
	}
}
