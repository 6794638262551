.pagination {
	.page-item {
		background-color: inherit !important;
		&.active .page-link {
			color: #fff !important;
			background-color: var(--primary) !important;
			border-color: var(--secondary) !important;
		}
	}
}

.pagination-blog {
	.page-item {
		margin-left: 0.25rem;
		margin-right: 0.25rem;
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
		.page-link {
			border-radius: $border-radius;
			border: none;
			padding: 0.75rem 1rem;
			font-weight: 500;
			font-size: 0.9rem;
		}
	}
}
