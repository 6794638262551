.media-img {
	height: 5rem;
	width: 5rem;
}

.media-map {
	height: 100%;
	width: 100%;
	.map-img {
		object-fit: cover;
		height: 100%;
		max-height: 270px;
		width: 100%;
	}
}
