.dropdown-notifications {
    position: static;
    .dropdown-menu {
        padding-top: 0;
        padding-bottom: 0;
        width: calc(100% - #{$grid-gutter-width});
        right: $grid-gutter-width / 2;
        max-height: 19rem;
        overflow-y: auto;
        overflow-x: hidden;
        .dropdown-notifications-header {
            background-color: $primary;
            color: $white;
            padding-top: 1rem;
            padding-bottom: 1rem;
            line-height: 1;
            svg {
                height: 0.7rem;
                width: 0.7rem;
                opacity: 0.7;
            }
        }
        .dropdown-notifications-item {
            padding-top: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid $gray-200;
            .dropdown-notifications-item-icon,
            .dropdown-notifications-item-img {
                height: 2.5rem;
                width: 2.5rem;
                border-radius: 100%;
                margin-right: 1rem;
                flex-shrink: 0;
            }
            .dropdown-notifications-item-icon {
                background-color: $primary;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    text-align: center;
                    font-size: 0.85rem;
                    color: $white;
                    height: 0.85rem;
                }
            }
            .dropdown-notifications-item-content {
                .dropdown-notifications-item-content-details {
                    color: $gray-500;
                    font-size: 0.7rem;
                }
                .dropdown-notifications-item-content-text {
                    font-size: 0.9rem;
                    max-width: calc(100vw - 8.5rem);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .dropdown-notifications-item-content-actions {
                    .btn-sm {
                        font-size: 0.7rem;
                        padding: 0.15rem 0.35rem;
                        cursor: pointer;
                    }
                }
            }
        }
        .dropdown-notifications-footer {
            justify-content: center;
            font-size: 0.8rem;
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            color: $gray-500;
            cursor: pointer;
            .dropdown-notifications-footer-icon {
                height: 1em;
                width: 1em;
                margin-left: 0.25rem;
            }
            &:active {
                color: $white;
            }
        }
        &::-webkit-scrollbar {
            width: 0.5rem;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10rem;
            height: 3em;
            border-width: 0.1rem;
            border-style: solid;
            background-clip: padding-box;
            background-color: $gray-400;
            border-color: rgba(0, 0, 0, 0);
        }
        &::-webkit-scrollbar-track {
            background-color: fade-out($gray-900, 0.95);
        }
        &::-webkit-scrollbar-button {
            width: 0;
            height: 0;
            display: none;
        }
        &::-webkit-scrollbar-corner {
            background-color: transparent;
        }
    }
    @include media-breakpoint-up(sm) {
        position: relative;
        .dropdown-menu {
            width: auto;
            min-width: 18.75rem;
            right: 0;
            .dropdown-notifications-item
                .dropdown-notifications-item-content
                .dropdown-notifications-item-content-text {
                max-width: 13rem;
            }
        }
    }
}
