.cluster-marker {
	/*color: var(--bs-white);*/
	color: #fff;
	background: var(--primary);
	border-radius: 50%;
	padding: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	font-size: medium;
	font-weight: normal;

	position: absolute;
	transform: translate(-50%, -50%);
}
