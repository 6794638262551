// Add styling for icons used within nav links
.nav,
.sidenav-menu {
	.nav-link .nav-link-icon {
		margin-right: 0.25rem;
	}
}

.breadcrumb {
	background-color: inherit;
	padding: 0;
	margin: 0;

	.breadcrumb-item:first-child::before {
		content: '' !important;
	}
	.breadcrumb-item::before {
		content: '›' !important;
	}
}
