.list-group-item {
	padding: 5px;
	width: 100%;

	@media (min-width: 768px) {
		width: 50% !important;
	}

	@media (min-width: 992px) {
		width: 33% !important;
	}

	label {
		border-radius: 5px;
		border-width: 1px;

		border-style: solid;
		border-color: $gray-500;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		cursor: pointer;
		//max-width: 380px;
		//min-width: 380px;
	}

	.text {
		background-color: $gray-200;
		border-bottom-left-radius: 4px;
		border-top-left-radius: 4px;
		border-width: 1px;
		color: $gray-600;

		padding: 1px;
		padding-left: 5px;
		margin: 2px;
		max-width: 130px;
		min-width: 130px;
	}
}
