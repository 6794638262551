// * * * * * * * * * * * * *
// * * Import variables  * *
// * * * * * * * * * * * * *

@import './scss/variables.scss';

// * * * * * * * * * * * * *
// * * Import Bootstrap  * *
// * * * * * * * * * * * * *

@import '~bootstrap/scss/bootstrap';

// * * * * * * * * * * * * * * *
// * * Import global styling * *
// * * * * * * * * * * * * * * *

@import './src/scss/global.scss';

// * * * * * * * * * * * *
// * * Import layouts  * *
// * * * * * * * * * * * *

@import './src/scss/layout/authentication.scss';
@import './src/scss/layout/dashboard-default.scss';
@import './src/scss/layout/dashboard-fixed.scss';
@import './src/scss/layout/dashboard-rtl.scss';
@import './src/scss/layout/error.scss';
@import './src/scss/layout/spinner.scss';
@import './src/scss/layout/balance-widget.scss';

// * * * * * * * * * * * * *
// * * Import utilities  * *
// * * * * * * * * * * * * *

@import './src/scss/utilities/animation.scss';
@import './src/scss/utilities/background.scss';
@import './src/scss/utilities/border.scss';
@import './src/scss/utilities/dropdown.scss';
@import './src/scss/utilities/general.scss';
@import './src/scss/utilities/lift.scss';
@import './src/scss/utilities/overflow.scss';
@import './src/scss/utilities/shadow.scss';
@import './src/scss/utilities/skew.scss';
@import './src/scss/utilities/text.scss';
@import './src/scss/utilities/zindex.scss';

// * * * * * * * * * * * * * * * *
// * * Import component styles * *
// * * * * * * * * * * * * * * * *

// -- Alerts
@import './src/scss/components/alerts/icon.scss';
//@import './src/scss/components/alerts/solid.scss';

// -- Avatars
@import './src/scss/components/avatars.scss';

// -- Buttons
@import './src/scss/components/buttons/global.scss';
@import './src/scss/components/buttons/icon.scss';
@import './src/scss/components/buttons/sizing.scss';
@import './src/scss/components/buttons/social.scss';
@import './src/scss/components/buttons/transparent.scss';

// -- Cards
@import './src/scss/components/cards/global.scss';
@import './src/scss/components/cards/advanced.scss';
@import './src/scss/components/cards/collapsable.scss';
@import './src/scss/components/cards/icon.scss';
@import './src/scss/components/cards/scrollable.scss';
@import './src/scss/components/cards/progress.scss';
@import './src/scss/components/cards/link.scss';
@import './src/scss/components/cards/flags.scss';

// -- Page header
@import './src/scss/components/page-header/page-header.scss';
@import './src/scss/components/page-header/page-header-dark.scss';
@import './src/scss/components/page-header/page-header-light.scss';

// -- Forms
@import './src/scss/components/forms/checkboxes.scss';
@import './src/scss/components/forms/form-control.scss';

// -- Navigation
@import './src/scss/components/navigation/nav.scss';
@import './src/scss/components/navigation/navbar.scss';
@import './src/scss/components/navigation/topnav.scss';
@import './src/scss/components/navigation/nav-sticky.scss';
@import './src/scss/components/navigation/sidenav/sidenav.scss';
@import './src/scss/components/navigation/sidenav/sidenav-dark.scss';
@import './src/scss/components/navigation/sidenav/sidenav-light.scss';

// -- Dropdowns
@import './src/scss/components/dropdowns/global.scss';
@import './src/scss/components/dropdowns/user.scss';
@import './src/scss/components/dropdowns/notifications.scss';

// -- Footer
@import './src/scss/components/footer.scss';

// -- Icons
@import './src/scss/components/icons.scss';

// -- Video
@import './src/scss/components/video.scss';

// -- Media
@import './src/scss/components/media.scss';

// -- Modals
@import './src/scss/components/modals.scss';

// -- Accordion
@import './src/scss/components/accordion.scss';

// -- Badges
@import './src/scss/components/badges.scss';

// -- Pagination
@import './src/scss/components/pagination.scss';

// -- List Group
@import './src/scss/components/list-group.scss';

// -- Custom Data Filter
@import './src/scss/components/datafilter.scss';
@import './src/scss/components/datalist.scss';

// -- Connector Statuses
@import './src/scss/components/connector-statuses.scss';

// * * * * * * * * * * * * * *
// * * Import page styles  * *
// * * * * * * * * * * * * * *

// -- Multi-tenant flow pages
//@import './src/scss/pages/auth-organizations.scss';

// -- Error page effect
@import './src/scss/pages/error.scss';

// * * * * * * * * * * * * * * *
// * * Import plugin styles  * *
// * * * * * * * * * * * * * * *

@import './src/scss/vendor/datatables.scss';
@import './src/scss/vendor/chartjs.scss';

// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// * * Import custom styles - add your own custom styles here! * *
// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

@import './src/scss/project-global.scss';

.cardBody {
	font-size: clamp(0.8rem, 2vw, 1rem);
	line-height: 1rem;

	@include media-breakpoint-up(lg) {
		line-height: 1.2rem;
	}
}

.breadcrumb {
	font-size: clamp(0.8rem, 2vw, 1rem);
}

.card-header {
	height: fit-content !important;
}
