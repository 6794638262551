.card-icon {
	.card-icon-aside {
		color: $white;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 3rem;
		padding: 0.5rem;
		i,
		svg,
		.feather {
			height: 3rem;
			width: 3rem;
		}
	}
}
