// Global overrides and restyling of the default Bootstrap dropdown menu

.dropdown-menu {
	font-size: 0.9rem;
	border: none;
	box-shadow: $box-shadow;
	.dropdown-header {
		font-size: 0.7rem;
		display: flex;
		align-items: center;
		@extend .text-uppercase-expanded;
	}
	.dropdown-item {
		display: flex;
		align-items: center;
		.dropdown-item-icon {
			line-height: 1;
			svg {
				height: 0.9em;
				width: 0.9em;
			}
		}
		&.active,
		&:active {
			.dropdown-item-icon {
				color: $white;
			}
		}
	}
}

.dropdown {
	.dropdown-toggle {
		display: inline-flex;
		align-items: center;
		.dropdown-arrow {
			margin-left: 0.4rem;
			margin-right: 0;
			transition: transform 0.1s ease-in-out;
			font-size: 0.6em;
		}
	}
	&.show {
		.dropdown-toggle .dropdown-arrow {
			transform: rotate(90deg);
		}
	}
}
