.spinner {
	flex: 1;
	padding: 1rem;
	height: 100%;
	width: 100%;

	.spinner-img {
		width: 5rem;
		height: 5rem;
	}
}
